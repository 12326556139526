import React from 'react';
import 'assets/scss/komsek.scss';

import Footer from 'components/footer';
import Header from 'components/header';
import SEO from 'components/seo';

const AboutPage = () => (
  <>
    <SEO title="O nás" />
    <Header />
    <article>
      <section>
        <div className="container grid-lg">
          <h1>O nás</h1>

          <h2>KOMSEK</h2>
          <ul>
            <li>Servisní organizace je 100% církevní</li>
            <li>Zavedení programu, kdy pracovník bude práci pro jednotlivý subjekt evidovat a následně alokovat tyto náklady</li>
            <li>Práce provedená pro subjekt bude na základě rámcové dohody o prováděných službách a na vyžádání oprávněného pracovníka</li>
            <li>Fakturace &ndash; fixní služby + vyžádané práce</li>
            <li>Konzultační návštěvy nemocnic a hotline samozřejmostí</li>
            <li>Pravidelná školení uživatelů, tréninky a workshopy pro lokální specialisty</li>
            <li>Možnost prohlížet a zpracovávat Vaše data na našem serveru &ndash; nebudeme Vám posílat pouze výstupy</li>
          </ul>

          <h2>Naše řešení</h2>
          <ul>
            <li>Vybudujeme systém, který bude respektovat specifika různých společností</li>
            <li>Budeme standardizovat a optimalizovat procesy a činnosti</li>
            <li>Budeme sdílet prostředky, vědomosti a zdroje</li>
          </ul>

          <h2>Cíle projektu</h2>
          <div className="columns">
            <div className="column">
              <h4>
                Krátkodobý<br/>
                <small>(okamžitý efekt)</small>
              </h4>
              <ul>
                <li>Kontrola procesů</li>
                <li>Monitoring činností jednotlivých pracovníků</li>
                <li>Zjištění efektivity jednotlivých oddělení i celých společností</li>
              </ul>
            </div>
            <div className="divider-vert" />
            <div className="column">
              <h4>
                Střednědobý<br/>
                <small>(efekt v řádu několika měsíců)</small>
              </h4>
              <ul>
                <li>Zvýšení tržeb/výnosů zdravotnických zařízení</li>
                <li>Snížení fixních nákladů</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </article>
    <Footer />
  </>
);

export default AboutPage;
